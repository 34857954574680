import { useQuery } from 'react-query';
import { request } from 'api/api';
import { UUID } from 'core/utils/BasicModels';

interface ResellerPlanQuotas {
  plan_id: UUID
  quota_id: UUID
  limit_reseller: number
}

export interface ICreateBody {
  name: string;
  seller_id: string;
  start_date: string;
  end_date: string;
  max_contract_duration: number;
  access_key_default_expiration_duration: number;
  reseller_plan_quotas: ResellerPlanQuotas[]
}

const distributionLicenseService = function () {
  const useDistributionLicensesByWorkspaceId = (workspaceId:string) => {
    return useQuery(
      ['useDistributionLicensesByWorkspaceId'],
      () => request.get(`/v2/workspaces/${workspaceId}/distribution-licenses`).then(res => res.data),
      {
        enabled: workspaceId,
        retry: false
      }
    );
  }

  const useDistributionLicenseById = (id: string) => {
    return useQuery(['useDistributionLicensceById'], 
      () => request.get(`/v2/distribution-licenses/${id}`).then(res => res.data),
      { retry: false }
    )
  }

  const editDistributionLicense = async function (id: string, body: any) {
    return await request.put(`/v2/distribution-licenses/${id}`, body);
  }

  const createDistributionLicense = async function (body: ICreateBody) {
    return await request.post('/v2/distribution-licenses', body);
  }

  const delteDistributionLicense = async function (id: string) {
    return await request.delete(`/v2/distribution-licenses/${id}`);
  }

  const useWorkspacesBySellerId = async (id: string) => {
    return await request.get(`/v2/workspaces/${id}`).then(res => res.data)
  }

  const useDistributionLicensesByWorkspaceIdAsync = async (workspaceId:string) => {
    const res = await request.get(`/v2/workspaces/${workspaceId}/distribution-licenses?include_distribution_quotas=true`).then(res => res.data);
    return res;
  }

  const useDistributionLicenseAsync = async (lastKey: any, pagination: any, sellers_ids?: [], apps_ids?: []) => {
    let size = pagination.current === 0 ? 10 : pagination.pageSize;
    let currentPage = pagination.current === 0 ? 1 : pagination.current;
    let payload: Record<string, any> = {};

    const fetchUrl = '/v2/distribution-licenses/query?include_distribution_quotas=true&size=' + size + '&page=' + currentPage + '&last_key=' + lastKey;

    if (sellers_ids?.length) {
      payload.sellers_ids = sellers_ids
    }

    if (apps_ids?.length) {
      payload.apps_ids = apps_ids
    }

    const result = request.post(fetchUrl, payload).then((res) => {
        return res;
      })
      
    return result;
  }

  return {
    useDistributionLicensesByWorkspaceId: useDistributionLicensesByWorkspaceId,
    useDistributionLicensesByWorkspaceIdAsync: useDistributionLicensesByWorkspaceIdAsync,
    useDistributionLicenseAsync: useDistributionLicenseAsync,
    useWorkspacesBySellerId: useWorkspacesBySellerId,
    useDistributionLicensceById: useDistributionLicenseById,
    editDistributionLicense: editDistributionLicense,
    createDistributionLicense: createDistributionLicense,
    delteDistributionLicense: delteDistributionLicense,
  };
}();

export default distributionLicenseService;
