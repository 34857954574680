export const AppConstants = {
    TERM_AND_CONDITIONS: '',
    VOUCHER_MAX_LIMIT: 1000,
    DATE_FORMATS: {
      YYYY_DASH_MM_DASH_DD_WITH_TIME: `yyyy-MM-DD'T'HH:mm:ss'Z'`,
      MMM_SPACE_YEAR: `MMM yyyy`,
      YYYY_DASH_MM_DASH_DD: 'YYYY-MM-DD',
      YYYY_DASH_MM_DASH_DDTHH_COLON_mm_COLON_ss: 'YYYY-MM-DDTHH:mm:ss',
      dddd_SPACE_DD_SPACE_MMM: 'dddd DD MMM',
      MMM_SPACE_DD_COMMA_SPACE_YYYY: 'MMM DD, YYYY',
      DD_SPACE_MMM_SPACE_YEAR: `DD MMM YY`,
      DD_SPACE_MMM_SPACE_YEAR_SPACE_HOUR_COLON_MINUTES: `DD MMM YY HH:mm`,
      DD_SPACE_MMM_SPACE_YEAR_SPACE_HOUR_COLON_MINUTES_WITH_TIMEZONE: `DD MMM YY HH:mmZ`,
      YYYY_MM_DD_HH_MM_SS: `YYYYMMDDHHmmss`,
      MMM_SPACE_DO_COMMA_SPACE_YEAR: `MMM Do, YY`,
      DO_SPACE_MMM_SPACE_DD_YYYY_HH_MM_SS_TIMEZONE: `ddd MMM DD YYYY HH:mm:ss [GMT]ZZ`
    },
    VALIDATIONS: {
      MOBILE_DIGITS_LENGTH: 20,
      MIN_MOBILE_DIGITS_LENGTH: 8,
      NAME_MAX_LENGTH: 50,
      COMMENTS_MAX_LENGTH: 100,
      ZIP_CODE_MAX_LENGTH: 15,
      CITY_MAX_LENGTH: 50,
      COUNTRY_MAX_LENGTH: 25,
      STATE_MAX_LENGTH: 50,
      FIELD_NAME_MAX_LENGTH: 25,
      EIN_MAX_LENGTH: 9,
      ADDRESS_MAX_LENGTH: 100,
      SEASONS_NAME_LENGTH: 50
    },
    NAME_MAX_LENGTH: 255,
    EMAIL_MAX_LENGTH: 352,
    PASSWORD_MAX_LENGTH: 50,
    ADD_FIELDS: {
      SEARCH_NAME_MAX_LENGTH: 25
    },
    DATE_PERIOD: {
      DATE_FORMAT: 'MMM DD, YYYY',
      NINE: 9,
      ONE: 1,
      SIX: 6,
      THREE_SIXTY_FIVE: 365,
      THREE_ZERO: 30,
      YEARS: 'years',
      MONTHS: 'months',
      DAYS: 'days'
    },
    CLOCK_FORMAT: {
      FORMAT_12: 'FORMAT_12',
      FORMAT_24: 'FORMAT_24'
    },
    DATE_FORMATS_SETTINGS: ['DD-MM-YYYY', 'DD-MM-YY', 'YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MMM-YYYY'],
    getTimeConstants: (t) => ({
      [AppConstants.CLOCK_FORMAT.FORMAT_12]: [
        '12:00 PM',
        '03:00 PM',
        '06:00 PM',
        '09:00 PM',
        '12:00 AM',
        '03:00 AM',
        '06:00 AM',
        '09:00 AM'
      ],
      [AppConstants.CLOCK_FORMAT.FORMAT_24]: [
        t('MIDDAY'),
        '15:00',
        '18:00',
        '21:00',
        t('MIDNIGHT'),
        '03:00',
        '06:00',
        '09:00'
      ]
    }),
    TASK_STATUS: (status, primaryStatus, t) => {
      const mapping = {
        PENDING: { text: t('Pending'), color: '#707374' },
        DELAYED: { text: t('Delayed'), color: '#F74141' }
      };
      return mapping[status] || primaryStatus;
    },
    TASK_DEFAULT_STATUS_COLOR: (status) =>
      ({
        Planned: '#707374',
        'In progress': '#EB8205',
        Complete: '#2FB966'
      }[status]),
    GRAPH_DEFAULT_COLORS: ['#EB8205', '#F9C43D', '#00A0BE'],
    O_AUTH: {
      responseCode: 'code',
      clientId: 'd8aae46f8f2e46c1a46b1d80f15d508b'
    },
    SUPPORTED_LANGS: [
      { value: 'en', name: 'English (USA)', shortLabel: 'EN' },
      { value: 'pt-BR', name: 'Portuguese', shortLabel: 'PT' },
      { value: 'fr', name: 'French', shortLabel: 'FR' },
      { value: 'de', name: 'German', shortLabel: 'DE' },
      { value: 'pl', name: 'Polski', shortLabel: 'PL' },
      { value: 'es', name: 'Spanish', shortLabel: 'ES' },
      { value: 'es-419', name: 'Spanish (Latin America)', shortLabel: 'ES-419' },
      { value: 'bg', name: 'Bulgarian', shortLabel: 'BG' },
      { value: 'hu', name: 'Hungarian', shortLabel: 'HU' },
      { value: 'ro', name: 'Romanian', shortLabel: 'RO' },
      { value: 'el', name: 'Greek', shortLabel: 'EL' },
      { value: 'nl', name: 'Dutch', shortLabel: 'NL' },
      { value: 'da', name: 'Danish', shortLabel: 'DA' },
      { value: 'sv', name: 'Swedish', shortLabel: 'SV' },
      { value: 'uk', name: 'Ukrainian', shortLabel: 'UK' },
      { value: 'ja', name: 'Japanese', shortLabel: 'JA' },
      { value: 'it', name: 'Italian', shortLabel: 'IT' },
    ],
    SUPPORTED_COUNTRIES: [
      { name: 'Argentina', value: 'AR' },
      { name: 'Austria', value: 'AT' },
      { name: 'Belgium', value: 'BE' },
      { name: 'Plurinational State of Bolivia', value: 'BO' },
      { name: 'Brazil', value: 'BR' },
      { name: 'Bulgaria', value: 'BG' },
      { name: 'Canada', value: 'CA' },
      { name: 'Chile', value: 'CL' },
      { name: 'Colombia', value: 'CO' },
      { name: 'Croatia', value: 'HR' },
      { name: 'Denmark', value: 'DK' },
      { name: 'France', value: 'FR' },
      { name: 'Germany', value: 'DE' },
      { name: 'Greece', value: 'GR' },
      { name: 'Hungary', value: 'HU' },
      { name: 'Ireland', value: 'IE' },
      { name: 'Italy', value: 'IT' },
      { name: 'Mexico', value: 'MX' },
      { name: 'Netherlands', value: 'NL' },
      { name: 'New Zealand', value: 'NZ' },
      { name: 'Paraguay', value: 'PY' },
      { name: 'Poland', value: 'PL' },
      { name: 'Portugal', value: 'PT' },
      { name: 'Romania', value: 'RO' },
      { name: 'Russian Federation', value: 'RU' },
      { name: 'Slovenia', value: 'SI' },
      { name: 'South Africa', value: 'ZA' },
      { name: 'Spain', value: 'ES' },
      { name: 'United Kingdom', value: 'GB' },
      { name: 'Ukraine', value: 'UA' },
      { name: 'United States', value: 'US' },
      { name: 'Guinea-Bissau', value: 'GW' },
    ]
  };  