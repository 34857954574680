import { useQuery, useMutation, useQueryCache } from 'react-query';
import { request } from 'api/api';
import { openNotificationWithIcon } from "../../common/Notification/NotificationToster";
import get from "lodash/get";
import { appServices, contractsService } from 'core/services';
import { useTranslation } from "react-i18next";
import { commonServices } from './commonServices';
import { IAddContractEntitiesDTO } from 'core/utils/BasicModels';
import { QuotasEntitiesDTO, RedeemedFromTypeEnum, RedeemInfoDTO } from './contracts.type';

var ContractsService = function () {

  let currentPage = 1;
  const useAllContracts = (searchQuery: any, pagination: any, setLoadingData?: any) => {
    const queryCache = useQueryCache();
    currentPage = pagination.page;
    const params = commonServices.getListQueryParams([`useAllContracts/${searchQuery.parentID}`, currentPage], searchQuery, pagination, queryCache);

    const response = useQuery(
      [`useAllContracts/${searchQuery.parentID}`, currentPage],
      () => request.get(`/v2/workspaces/${searchQuery.parentID}/contracts?include_waiting_for_payment=true`, { params: params }).then(async (res) => {
        const { data } = res;

        if (res.data) {
          for (let i = 0; i < data.content.length; i++) {
            const appId = (data.content[i]) ? data.content[i].app_id : '';
            const { data: app } = await request.get(`/v2/apps/${appId}/info?include_unpublished_apps=true`);
            if (app) {
              data.content[i].appName = app.name;
            } else if (data.content[i]) {
              data.content[i].appName = '-'
            }
          }
        }

        if (setLoadingData) {
          setLoadingData(false);
        }

        return data;
      }),
      {
        enabled: searchQuery.parentID,
        keepPreviousData: true,
        retry: false
      }
    );
    
    return response;
  }

  const useGetMetaTitle = (searchQuery: any) => {
    const { data: app } = appServices.useGetAppById(searchQuery.parentID[1]);
    return useQuery(
      [`useAllContracts/metaInfo`, searchQuery.parentID[0]],
      () => request.get(`/v2/plans/${searchQuery.parentID[0]}`).then(res => {
        res.data.name = `${app.name} - ${res.data.name}`;
        return res.data && res.data.content;
      }),
      {
        enabled: app,
        retry: false
      }
    );
  }

  const handleUpdateContractEntities = async (contractId: string, contractQuotas: QuotasEntitiesDTO[]) => {
    if (contractQuotas?.length > 0) {
      await Promise.all(contractQuotas.map(async (quota) => {
        if (quota.entitiesToBeRemoved?.length > 0) {
          const payload = quota.entitiesToBeRemoved;
          // need to wait for deletion first so we can enable quota limit if the update surpasses the max_limit
          await contractsService.removeContractEntities(contractId, payload);
        }
        if (quota.entitiesToBeAdded?.length > 0) {
          const payload = {
            quota: quota.id,
            entities: quota.entitiesToBeAdded
          };
          contractsService.addContractEntities(contractId, payload);
        }
      }))
    }
  }

  const useCreateEditContract = () => {
    const { t } = useTranslation();
    return useMutation(
      (params) => {
        if (params.id) {
          const {quotasEntities, quotas, start, end, notes, id} = params
          handleUpdateContractEntities(id, quotasEntities);
          const updatedQuotas = Object.entries(quotas).map(([quota_id, limit]) => ({quota_id, limit}))
          return request.put(`/v2/contracts/${id}`, {
            start,
            end,
            notes,
            quotas: updatedQuotas
          });
        } else {
          const {quotasEntities: quotas, quotas:__, ...rest} = params
          return request.post(`/v2/workspaces/${params.accountId}/contracts`, { ...rest, quotas });
        }
      },
      {
        onMutate: (params: any) => {
        },
        onSuccess: (response, params) => {
          openNotificationWithIcon(
            'success',
            params.id ? t('Contract updated successfully') : t('Contract created successfully'),
            'bottomRight'
          );
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            "error",
            `${t('sorry, something wrong happened. Response from server is')} : 
            ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
            "bottomLeft"
          );
          if (rollback) rollback()
        },
        onSettled: () => {

        }
      }
    );
  }

  const useGetContractById = (contractId: string, accountId: string) => {
    return useQuery(
      ['useGetContractById', contractId],
      () => request.get(`/v2/workspaces/${accountId}/contracts/${contractId}`).then(res => res.data),
      {
        enabled: contractId && contractId !== '' && accountId,
        retry: false
      }
    );
  }

  const useGetDistributionLicenseByAccessKey = (redeem_info?: RedeemInfoDTO) => {
    return useQuery(
      ['useGetAccessKeyByCode', redeem_info?.redeemed_from_id],
      () => request.get(`/v2/access-keys/${redeem_info?.redeemed_from_id}`).then(response => {
        const {distribution_licensing_id} = response.data
        return request.get(`/v2/distribution-licenses/${distribution_licensing_id}`).then(res => res.data)
      }),
      {
        enabled: redeem_info?.redeemed_from_id && redeem_info?.redeemed_from_type === RedeemedFromTypeEnum.ACCESS_KEY,
        retry: false
      }
    );
  }

  const fetchContractEntities = async (contractId: string) => {
    try {
      const contractEntitiesResponse = await request.get(`/v2/contracts/${contractId}/entities`);
      return contractEntitiesResponse.data.content;
    } catch (err) {
      openNotificationWithIcon(
        "error",
        'Could not fetch contracts entities due to the following error: ' + err
      );
    }
  }

  const addContractEntities = async (contractId: string, payload: IAddContractEntitiesDTO) => {
    try {
      const addContractEntitiesResponse = await request.post(`/v2/contracts/${contractId}/entities`, payload);
      return addContractEntitiesResponse.data;
    } catch (err) {
      openNotificationWithIcon(
        "error",
        'Could not add contract entities due to the following error: ' + err
      );
    }
  }

  const removeContractEntities = async (contractId: string, entityIds: string[]) => {
    try {
      const removeContractEntitiesResponse = await request.delete(`/v2/contracts/${contractId}/entities`, { data: entityIds });
      return removeContractEntitiesResponse.data;
    } catch (err) {
      openNotificationWithIcon(
        "error",
        'Could not remove contract entities due to the following error: ' + err
      );
    }
  }

  const useUpdateQuotaUsage = () => {
    const { t } = useTranslation();
    return useMutation(
      (params) => {
        const updateParams = {...params}
        delete updateParams.id;
        delete updateParams.original_id;
        return request.put(`/v2/workspaces/${params.account_id}/contracts/${params.id}`, updateParams);
      },
      {
        onMutate: (params: any) => {
        },
        onSuccess: (response, params) => {
          openNotificationWithIcon(
            'success',
            t('Quota usage updated successfully'),
            'bottomRight'
          );
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            "error",
            `${t('sorry, something wrong happened. Response from server is')} : 
            ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
            "bottomLeft"
          );
          if (rollback) rollback()
        },
        onSettled: () => {
        }
      }
    );
  }

  const useDeleteContractById = () => {
    const { t } = useTranslation();
    const queryCache = useQueryCache();
    return useMutation(({ accountId, contractId }) => request.delete(`/v2/workspaces/${accountId}/contracts/${contractId}`),
      {
        onMutate: (id) => {
          const oldContracts = queryCache.getQueryData([`useAllContracts`, currentPage]);
          return () => queryCache.setQueryData([`useAllContracts`, currentPage], oldContracts);
        },
        onSuccess: () => {
          openNotificationWithIcon('success', t('Contract deleted successfully'), 'bottomRight');
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            'error',
            `${t('Sorry, something wrong happened. Response from server is')} : 
            ${get(error, 'response.status')}, ${get(error, 'response.data.error')}`,
            'bottomLeft'
          );
          if (rollback) rollback()
        },
        onSettled: () => {
          queryCache.invalidateQueries([`useAllContracts`, currentPage], { exact: true });
        }
      }
    )
  }

  return {
    useAllContracts: useAllContracts,
    useGetMetaTitle: useGetMetaTitle,
    useCreateEditContract: useCreateEditContract,
    useGetContractById: useGetContractById,
    useUpdateQuotaUsage: useUpdateQuotaUsage,
    useDeleteContractById: useDeleteContractById,
    fetchContractEntities: fetchContractEntities,
    addContractEntities: addContractEntities,
    removeContractEntities: removeContractEntities,
    useGetDistributionLicenseByAccessKey: useGetDistributionLicenseByAccessKey,
  };
}();

export default ContractsService;
