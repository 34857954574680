import { AxiosResponse } from 'axios';
import { axiosRequest } from 'core/axios';
import { getHeaders } from 'core/common-methods';
import { OrganizationDTO } from 'core/orgs/models';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import { PageDTO, UUID } from 'core/utils/BasicModels';
import { Dispatch } from 'redux';

export const ACTION_GET_ORGS_LIST: RequestAction = createRequestAction('GET_ORGS_LIST_REQUEST');
export const ACTION_SET_CURRENT_ORG = 'ACTION_SET_CURRENT_ORG';

export const fetchAllOrgs = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_GET_ORGS_LIST.REQUEST });
    const allOrgs = await axiosRequest
        .get<any, AxiosResponse<PageDTO<OrganizationDTO>>>(`/v2/orgs`,
      {
        headers: getHeaders().common,
      });

    dispatch({ type: ACTION_GET_ORGS_LIST.SUCCESS, payload: allOrgs.data });
    return allOrgs.data;
  } catch (e) {
    dispatch({ type: ACTION_GET_ORGS_LIST.ERROR, payload: e });
    throw e;
  }
};

export const setCurrentOrg = (currentOrgId: UUID) => async (dispatch: Dispatch) => {
  dispatch({ type: ACTION_SET_CURRENT_ORG, payload: currentOrgId });
};
