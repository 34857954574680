import {createRequestAction, RequestAction} from "core/utils/actionUtils";
import { Dispatch } from 'redux';

export const ACTION_GET_APPS_LIST: RequestAction = createRequestAction('GET_APPS_LIST_REQUEST');
export const ACTION_CREATE_APP: RequestAction = createRequestAction('CREATE_APP_REQUEST');
export const ACTION_GET_APP: RequestAction = createRequestAction('GET_APP_REQUEST');
export const ACTION_RESET_GET_APP: RequestAction = createRequestAction('RESET_GET_APP_REQUEST');

export const fetchApps = () => async (dispatch: Dispatch) => {
    try{
        dispatch({ type: ACTION_GET_APPS_LIST.REQUEST })

        const data = [
            {key: '0', id: '21375af3-2473-4aa3-9519-96c184c0b9f4', name: 'Cropwise Protector', description: 'March 06, 2020', status: 'Published'},
            {key: '1', id: '0ffd6515-676a-430c-8d4b-ad6b1c3b4aaa', name: 'Cropwise Imagery', description: 'August 28, 2020', status: 'Published'},
            {key: '2', id: '11f357e0-7b38-4ad0-bd2d-b453b2b08308', name: 'Cropwise Dev Portal', description: 'August 28, 2020', status: 'Unpublished'},
            
        ];

        dispatch({ type: ACTION_GET_APPS_LIST.SUCCESS, payload: data })

        return data;
    } catch(e){
        dispatch({ type: ACTION_GET_APPS_LIST.ERROR, payload: e });
        throw e;
    }
}

export const createApps = () => async (dispatch: Dispatch) => {
    try{
        dispatch({ type: ACTION_CREATE_APP.REQUEST})

        const data = {
            app:{
                key: '0', 
                name: 'Cropwise Protector', 
                description: 'March 06, 2020', 
                status: 'Unpublished'
            },
            client:{
                id: '47656E6572617465644279436169756269', 
                secret: 'NCSZTXZ3SQUUJ35QZE632QCT4VAJG8R2'
            }
        }

        dispatch({ type: ACTION_CREATE_APP.SUCCESS, payload: data })
        return data;
        
    } catch(e){
        dispatch({ type: ACTION_CREATE_APP.ERROR, payload: e});
        throw e;
    }
}

export const getApp = () => async (dispatch: Dispatch) => {
    try{
        dispatch({ type: ACTION_GET_APP.REQUEST })

        const data = '21375af3-2473-4aa3-9519-96c184c0b9f4';

        dispatch({ type: ACTION_GET_APP.SUCCESS, payload: data })

        return data;
    } catch(e){
        dispatch({ type: ACTION_GET_APP.ERROR, payload: e });
        throw e;
    }
}

export const resetGetApp = () => async(dispatch: Dispatch) => {
    try{
        dispatch({ type: ACTION_RESET_GET_APP.REQUEST })

        const data = '';

        dispatch({ type: ACTION_RESET_GET_APP.SUCCESS, payload: data })

        return data;
    } catch(e){
        dispatch({ type: ACTION_RESET_GET_APP.ERROR, payload: e });
        throw e;
    }
}