import React from 'react';
import styled from 'styled-components';
import { ErrorBoundary } from 'components/error-boundary';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const StyledLoader = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
`;

interface ILoaderProps {
    tip: string;
}

export const Loader: React.FC<ILoaderProps> = (props) => {
    return (
        <ErrorBoundary>
            <StyledLoader>
                <Spin spinning delay={500} tip={props.tip} indicator={<LoadingOutlined style={{ fontSize: 30 }} spin={true} />}></Spin>
            </StyledLoader>
        </ErrorBoundary>
    );
}

  