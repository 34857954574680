import { useQuery } from 'react-query';
import { request } from 'api/api';
import { openNotificationWithIcon } from 'common/Notification/NotificationToster';

var appService = function () {

  const useApps = () => {
    return useQuery(
      ['apps'],
      () => request.get('/v2/orgs/43414955-4249-5359-4e47-454e54413432/apps').then(res => res.data.content),
      {
        retry: false
      }
    );
  };

  const fetchApps = async () => {
    try {
      const res = await request.get('/v2/orgs/43414955-4249-5359-4e47-454e54413432/apps');
      return res.data.content;
    } catch (error) {
      openNotificationWithIcon(
        "error",
        `${('something went wrong, please try again')}`
      );
    }
  };

  const useGetAppById = (id: string) => {
    return useQuery(
      [`apps`, id],
      () => request.get(`/v2/apps/${id}`).then(res => res.data),
      {
        enabled: id,
        retry: false
      }
    );
  }

  const useGetAppInfoById = (id: string) => {
    return useQuery(
      [`apps`, id],
      () => request.get(`/v2/apps/${id}/info?include_unpublished_apps=true`).then(res => res.data),
      {
        enabled: id,
        retry: false
      }
    );
  }

  return {
    useApps: useApps,
    fetchApps: fetchApps,
    useGetAppById: useGetAppById,
    useGetAppInfoById: useGetAppInfoById
  };
}();

export default appService;
