import { notification } from "antd";

export const openNotificationWithIcon = (type, message, placement, description, style, duration) => {
  notification[type]({
    message: message,
    description,
    style: {
      height: "100%",
      ...style
    },
    placement,
    duration
  });
};
