import { request } from "api/api";
import { openNotificationWithIcon } from "common/Notification/NotificationToster";
import moment from "moment";
import { AppConstants } from "core/utils/app.constants";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

interface IParams {
  name?: string;
}

const usersData = [
  {
    key: '0',
    name: 'Rohit Group',
    id: '001',
    role: 'Default',
    mobileNumber: '+91 9975580530',
  },
  {
    key: '1',
    name: 'Anand Group',
    id: '002',
    role: 'Default',
    mobileNumber: '+91 9975580530',
  },
  {
    key: '3',
    name: 'Babu Group',
    id: '003',
    role: 'Default',
    mobileNumber: '+91 9975580530',
  },
];

const mapUserResultsToUserTable = (user: any) => {
  const createdIn = `${moment(user.created_at).format(AppConstants.DATE_FORMATS.DD_SPACE_MMM_SPACE_YEAR_SPACE_HOUR_COLON_MINUTES)}h`;
  return {
    key: user.id,
    name: user.name,
    id: user.id,
    email: user.email,
    createdIn,
    role: user.role,
    mobileNumber: user.phone,
  }
}

const userService = function () {
  const useAllUsersname = (searchQueary: any) => {
    return useQuery(
      `useAllUsersname/${searchQueary.searchInputValue}`,
      () =>
        request
          .get(`/v2/accounts/by-name?name=${searchQueary.searchInputValue}`)
          .then((res) => {
            return { content: res.data.content.map(mapUserResultsToUserTable) };
          }),
      {
        enabled:
          searchQueary.searchInputValue !== "" &&
          searchQueary.searchUserClicked,
        onSuccess: () => searchQueary.setSearchUserClicked(false),
      }
    );
  };

  const useAllUsersemail = (searchQueary: any) => {
    return useQuery(
      `useAllUseremail/${searchQueary.searchInputValue}`,
      () =>
        request
          .get(`/v2/accounts?login=${searchQueary.searchInputValue}`)
          .then((res) => {
            return { content: [mapUserResultsToUserTable(res.data)] };
          }),
      {
        retry: false,
        enabled:
          searchQueary.searchInputValue !== "" &&
          searchQueary.searchUserClicked,
        onSuccess: () => searchQueary.setSearchUserClicked(false),
      }
    );
  };

  const currentUser = async () => {
    const res = await request.get('/v2/accounts/me?include_extended=true&attributes=licensing_accounts,is_using_rbac');
    return res.data;
  }

  const useAllRegistered = (searchQuery:any) => {
    let params:IParams = {};
    if('searchInputValue' in searchQuery && searchQuery.searchInputValue !== ''){
      params.name= searchQuery.searchInputValue;
    }
    return useQuery(
      `useAllRegistered`,
      () => {
        return {content: usersData}
      }
    );
  }

  const useGetUserById = (accountId: string) => {
    return useQuery(['useGetUserById', accountId], () => request
      .get(`/v2/accounts/${accountId}`)
      .then(res => res.data));
  }

  const useGetUserRolesByLevel = (accountId: string, level: string) => {
    return useQuery([`useGetUserRolesByLevel/${level}`, accountId], () => {
      if (accountId) {
        return request
          .get(`/v2/_proxy/accounts/${accountId}/roles/${level}`)
          .then(res => res.data);
      }
      return undefined;
    });
  }

  const sendEmailToResetPassword = async (params: any, t: any) => {
    await request
      .post("/v2/accounts/recovery", params, {
        headers: {
          "x-origin": process.env.REACT_APP_ACCOUNTS_CW_URL,
        },
      })
      .then(() => {
        openNotificationWithIcon(
          'success', 
          t("Password recovery e-mail sent successfully."), 
          'topRight'
        );
      }).catch((error: any) => {
        openNotificationWithIcon(
          'error', 
          t('There was an error sending the password recovery e-mail. Try again.'),
          'topRight'
        );
      });
  }

  const deleteAccountById = async (accountId: string, t: any) => {
    await request.delete(`/v2/accounts/${accountId}`)
      .then(() => {
        openNotificationWithIcon(
          'success', 
          t('User has been successfully deleted.'), 
          'topRight'
        );
      }).catch((error: any) => {
        openNotificationWithIcon(
          'error', 
          t('There was an error deleting the user. Try again.'),
          'topRight'
        );
      });
  }

  const useCurrentUser = () => {
    return useQuery(['useCurrentUser'], () => request
      .get(`/v2/accounts/me?include_extended=true&attributes=licensing_accounts,is_using_rbac`)
      .then(res => res.data));
  }

  const useAllPendingusers = (searchQuery:any) => {
    let params:IParams = {};
    if('searchInputValue' in searchQuery && searchQuery.searchInputValue !== ''){
      params.name= searchQuery.searchInputValue;
    }
    return useQuery(
      `useAllPending`,
      () => {
        return {content: usersData}
      }
    );
  }

  const useChangeProvider = () => {
    const { t } = useTranslation();
    const queryCache = useQueryCache();
    return useMutation((params) => request.put(`/v2/accounts/${params.id}`, params),
      {
        onSuccess: (__, params) => {
          openNotificationWithIcon('success', t("users.user_details.notification.provider_successfully_changed"), 'bottomRight');
          queryCache.invalidateQueries(['useGetUserById', params.id], { exact: true });
        },
        onError: (error: any, _params: any) => {
          openNotificationWithIcon(
            "error",
            `${t('sorry, something wrong happened. Response from server is')} : 
            ${get(error, "response.status")}, ${get(error, "response.data.error")}`,
            "bottomLeft"
          );
        }
      }
    )
  }

  return {
    currentUser: currentUser,
    useAllRegistered: useAllRegistered,
    useAllPendingusers: useAllPendingusers,
    useCurrentUser: useCurrentUser,
    useGetUserById: useGetUserById,
    useGetUserRolesByLevel: useGetUserRolesByLevel,
    useAllUsersname: useAllUsersname,
    useAllUsersemail: useAllUsersemail,
    sendEmailToResetPassword: sendEmailToResetPassword,
    deleteAccountById: deleteAccountById,
    useChangeProvider,
  };
}();

export default userService;
