import { ACTION_GET_ORGS_LIST, ACTION_SET_CURRENT_ORG } from 'app-redux/actions/org.actions';
import { OrganizationDTO } from 'core/orgs/models';
import { PayloadAction } from 'core/utils/actionUtils';
import { PageDTO, UUID } from 'core/utils/BasicModels';

export interface OrgsState {
  readonly orgs: OrgMap;
  currentOrgId?: UUID;
  isLoadingOrgs: boolean;
  error?: any;
}

export interface OrgMap {[id: string]: OrganizationDTO; }

const initialState: OrgsState = {
  isLoadingOrgs: false,
  orgs: {},
};

const orgListToMap = (orgs: readonly OrganizationDTO[]): OrgMap => {
  return orgs.reduce((orgMap, org) => ({
    ...orgMap,
    [org.id]: org,
  }), {});
};

export const orgsStateReducer = (state: OrgsState = initialState, action: PayloadAction<any>): OrgsState => {
  switch (action.type) {
    case ACTION_GET_ORGS_LIST.REQUEST:
      return {
        ...state,
        isLoadingOrgs: true,
      };
    case ACTION_GET_ORGS_LIST.SUCCESS:
      return {
        ...state,
        orgs: {
          ...state.orgs,
          ...orgListToMap((action.payload as PageDTO<OrganizationDTO>).content),
        },
        isLoadingOrgs: false,
      };
    case ACTION_GET_ORGS_LIST.ERROR:
      return {
        ...state,
        isLoadingOrgs: false,
        error: action.payload,
      };
    case ACTION_SET_CURRENT_ORG:
      return {
        ...state,
        currentOrgId: action.payload,
      };
    default:
      return state;
  }
};
