import * as Sentry from '@sentry/react';
import { Route } from 'react-router-dom';
import { history } from 'core/history';

export const SENTRY_KEY = process.env.REACT_APP_SENTRY_DSN ?? '';
const APP_ENVIRONMENT = process.env.REACT_APP_ENV ?? '';
const SAMPLE_RATE = process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? 1.0;
const NORMALIZE_DEPTH = 5;

export const SentryRoute = Sentry.withSentryRouting(Route);

export const SENTRY_CONFIG: Sentry.BrowserOptions = {
  dsn: SENTRY_KEY,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history })
  ],
  tracesSampleRate: Number(SAMPLE_RATE),
  // release: `v${APP_VERSION}`,
  environment: APP_ENVIRONMENT,
  normalizeDepth: NORMALIZE_DEPTH,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb?.category?.startsWith('ui')) {
      const target = hint?.event.target;
      const dataTestId = target.dataset.testid;
      const customMessage = `[data-testid="${dataTestId}"]${breadcrumb.message}`;
      breadcrumb.message = dataTestId ? customMessage : breadcrumb.message;
    }
    return breadcrumb;
  }
};