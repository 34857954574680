import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const RoutePaths = {
  HOME: () => '/',
  DASHBOARD: () => '/app',
  PRODUCTS_PAGE: () => '/app/products',
  PROFILE_PAGE: () => '/app/profile',
  HOME_PAGE: () => '/app/home',
  NEW_CONTRACT_PAGE: (aid = ':account_id') => `/app/account/${aid}/contracts/create`,
  EDIT_CONTRACT_PAGE: (aid = ':account_id', id = ':id') => `/app/account/${aid}/contracts/edit/${id}`,
  CONTRACTS_PAGE: (aid = ':account_id') => `/app/account/${aid}/contracts`,
  PLANS_PAGE: (pid = ':product_id') => `/app/products/${pid}/plans`,
  USERS_PAGE: () => `/app/users`,
  USER_DETAILS_PAGE: (id = ':id') => `/app/users/${id}`,
  PENDING_USERS_PAGE: (sub = ':pending-users') => `/app/users/${sub}`,
  CAMPAIGN_PAGE: () => '/app/campaigns',
  CAMPAIGN_DETAILS_PAGE: (id = ':id') => `/app/campaigns/${id}`,
  CAMPAIGN_EDIT_PAGE: (id = ':id') => `/app/campaigns/edit/${id}`,
  CROPS_PAGE: () => '/app/crops',
  CROP_DETAILS_PAGE: (id = ':id') => `/app/crops/${id}`,
  GROWTH_SCALES_PAGE: () => '/app/growth-scales',
  GROWTH_SCALES_DETAILS_PAGE: (id = ':id') => `/app/growth-scales/${id}`,
  DISTRIBUTION_LICENSE_PAGE: () => '/app/distribution-license',
  WORKSPACES_PAGE: () => '/app/workspaces',
  ORGANIZATION_DETAILS_PAGE: (id = ':id') => `/app/organizations/${id}`,
  ORGANIZATIONS_EDIT_PAGE: (id = ':id') => `/app/organizations/edit/${id}`,
  NO_ACCESS_PAGE: () => `/app/no-access`,
  ACCESS_REQUEST_PAGE: () => '/app/acess-request-page',
  WORKSPACES_DETAILS_PAGE: (id = ':id') => `/app/workspace/${id}`,
  ACCOUNT_EDIT_PAGE: (id = ':id') => `/app/account/edit/${id}`,
  DISTRIBUTION_LICENSE_EDIT_PAGE: (id = ':id') => `/app/distribution-license/edit/${id}`,
  DISTRIBUTION_LICENSE_CREATE_PAGE: () => `/app/distribution-license/create`,
  INVITE_MEMBERS_PAGE: () => `/app/invite-members`,
  CATALOG_PAGE: () => `/app/catalogs`,
  SEEDS_CATALOG_PAGE: () => `/app/catalogs/seeds`,
  GENERIC_ITEMS_CATALOG_PAGE: () => `/app/catalogs/generic-items`,
  CROP_PROTECTION_CATALOG_PAGE: () => `/app/catalogs/crop-protection`,
  QUOTA_MGT_PAGE: (aid = ':account_id') => `/app/account/${aid}/quota-mgt`,
};

export const PublicRoutes = [
  RoutePaths.HOME(),
];





