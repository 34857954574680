export interface IParams {
  name?: string;
  size?: number,
  page?: number,
  last_key?: string,
  attributes?: string,
  force_list_unexplicit_orgs?: boolean,
  licensing_accounts?: string,
  workspaces?: string,
}

export const commonServices = function () {
  
  let oldSearchInut = '';
  let oldPageSize = 0;
  const getListQueryParams = (queryKey: any, searchQuery: any, pagination: any, queryCache: any) => {
    let params:IParams = {};    
    params.size = pagination.size;
    params.page = pagination.page;
    params.last_key = pagination.last_key;
    
    if((searchQuery?.searchInputValue  && searchQuery?.searchInputValue !== '') || oldSearchInut !== ''){
      if(searchQuery.searchInputValue !== '') {
        params.name= searchQuery.searchInputValue;
      }
      if(oldSearchInut !== searchQuery.searchInputValue){
        oldSearchInut = searchQuery.searchInputValue;
        queryCache.removeQueries(queryKey);
      }
    }    

    if(oldPageSize !== pagination.size){
        oldPageSize = pagination.size;
        queryCache.removeQueries(queryKey);
    }

    return params;
  }

  return {
    getListQueryParams: getListQueryParams,
  }
}()
