import { Button } from "antd";
import React from "react";

export const CreatedWorkspaceNotificationContent = ({t}: any) => {
  const onReloadPage = () => window.location.reload();
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {t('It may take a little while for you to get access to edit your workspace. Please reload this page in a few moments.')}
      <Button
        style={{marginRight: '5px', backgroundColor: '#14803C', color: '#FFFFFF', alignSelf: 'flex-end' }}
        onClick={onReloadPage}
        type='primary'
        prefixCls="syngenta-ant-btn"
        data-testid="notification-button"
      >
        {t('Reload page')}
      </Button>
    </div>
  );
}