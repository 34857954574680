import { find } from 'lodash';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import { history, RoutePaths } from './history';
import jwtDecode from 'jwt-decode';
import { CookieStorage } from 'cookie-storage';

const cookieStorage = new CookieStorage();

export const isAccessTokenAvailable = () => !!localStorage.getItem('tokens');
export const setAccessToken = (token: any) => localStorage.setItem('tokens', token);
export const getAccessToken = () => localStorage.getItem('tokens') || '';
export const clearAccessToken = () => localStorage.removeItem('tokens');

export const getProgressByValues = (isPasswordValid: boolean, isMobileNumberValid: boolean, termsCheckbox: boolean) => {
  let count = 1;
  if (isPasswordValid) {
    count++;
  }
  if (isMobileNumberValid) {
    count++;
  }
  if (termsCheckbox) {
    count++;
  }
  return count;
};

export const isEmptyStr = (str?: string) => {
  if (str && str.length > 0) {
    return false;
  }
  return true;
};

export const getHeaders = () => ({
  common: {
    Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`,
  },
});

export const getStoredLanguage = () => {
  let lang = localStorage.getItem('i18nextLng') || 'en';
  if (lang === 'pt') {
    lang = 'pt-BR';
  }
  return lang;
};

export const getLangHeaders = () => {
  return {
    common: {
      'Authorization': `Bearer ${JSON.parse(getAccessToken()).access_token}`,
      'Accept-Language': getStoredLanguage(),
    },
  };
};

export const getInitialName = (name: string) => {
  if (isEmpty(name)) {
    return '';
  }
  const splitedNames = name.split(' ');
  const firstName = first(splitedNames) || '';
  const lastName = last(splitedNames) || '';
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const getLanguageLocale = () => {
  let selectedLang = localStorage.getItem('i18nextLng') || navigator.language;
  let selectedCode = selectedLang.includes('pt') ? 'pt': 'en';
  return selectedCode;
}

export const userCanAccess = (user: any[]) => {
  return user.filter(v => v.id === '43414955-4249-5359-4e47-454e54413432').length > 0;
}

const sytAccess = process.env.REACT_APP_REQUIRE_SYT_DIGITAL_ACCESS;

export const checkUserAccess = (res:any) => {
  if (sytAccess
        && res.data.authorities !== undefined 
          && !userCanAccess(res.data.authorities)) {
    history.push(RoutePaths.HOME_PAGE());
  }
}

export const checkErrorUserAccess = (error:any) => {
  const authStatusArr = [401, 403];
  if(authStatusArr.indexOf(get(error, "response.status")) !== -1){
    history.push(RoutePaths.NO_ACCESS_PAGE());
  }
}

export const tokenBelongsToAuthority = (authorizationToken: any, authority: any) => {
  try {
    const decoded: any = jwtDecode(authorizationToken);
    return (decoded.authorities || []).indexOf(authority) > -1;
  } catch (err) {
    return false;
  }
};

export const isUserPrivileged = () => {
  try {
    const token = JSON.parse(getAccessToken());
    return (
      tokenBelongsToAuthority(token.access_token, 'SUPER_USER') ||
      tokenBelongsToAuthority(token.access_token, 'SUPPORT')
    );
  } catch (err) {
    return false;
  }
};

export const isSupportUser = () => {
  try {
    const token = JSON.parse(getAccessToken());
    return tokenBelongsToAuthority(token.access_token, 'SUPPORT')
  } catch (err) {
    return false;
  }
}

export const isSuperUser = () => {
  try {
    const token = JSON.parse(getAccessToken());
    return tokenBelongsToAuthority(token.access_token, 'SUPER_USER')
  } catch (err) {
    return false;
  }
}

export const getDeepActionUrl = () => {
  if (isUK()) {
    return process.env.REACT_APP_FARM_SETTINGS_UK_URL;
  } else {
    return process.env.REACT_APP_FARM_SETTINGS_CW_URL;
  }
};

export const getDeepActionCBUrl = () => {
  return `${window.location.origin}${window.location.pathname}`
}

export const isPermissionAccess = (permissions: any, action: string) => {
  return find(permissions, { action });
};

export const isPermissionAccessStrict = (permissions: any, action: string, resource: string) => {
  return find(permissions, (operations) => operations.action === action && operations.resource === resource);
}

export const isUK = () => {
  const { host } = getAppHostsInfo();
  if (host === 'UK') {
    return true;
  }
  return false;
};

const qaLinkConstant = 'qa.accounts.cropwise.com';

export const getAppHostsInfo : any = () => {
  const domainMapping:any = {
    'localhost:3000': { host: 'GLOBAL', account: qaLinkConstant },
    'localhost:4000': { host: 'UK', account: 'uk-qa.accounts.cropwise.com' },
    'localhost:5000': { host: 'UK', account: 'qa.accounts.syngentadigital.co.uk' },
    'beta.app.cropwise.com': { host: 'GLOBAL', account: 'beta.accounts.cropwise.com' },
    'beta.app.syngentadigital.co.uk': {
      host: 'UK',
      account: 'beta.accounts.syngentadigital.co.uk'
    },
    'dev.app.cropwise.com': { host: 'GLOBAL', account: 'dev.accounts.cropwise.com' },
    'uk-dev.app.cropwise.com': { host: 'UK', account: 'uk-dev.accounts.cropwise.com' },
    'dev.app.syngentadigital.co.uk': { host: 'UK', account: 'dev.accounts.syngentadigital.co.uk' },
    'qa.app.cropwise.com': { host: 'GLOBAL', account: qaLinkConstant },
    'staff.qa.cropwise.com': { host: 'GLOBAL', account: qaLinkConstant },
    'uk-qa.app.cropwise.com': { host: 'UK', account: 'uk-qa.accounts.cropwise.com' },
    'qa.app.syngentadigital.co.uk': { host: 'UK', account: 'qa.accounts.syngentadigital.co.uk' },
    'staging.app.cropwise.com': { host: 'GLOBAL', account: 'staging.accounts.cropwise.com' },
    'uk-staging.app.cropwise.com': { host: 'UK', account: 'uk-staging.accounts.cropwise.com' },
    'staging.app.syngentadigital.co.uk': {
      host: 'UK',
      account: 'staging.accounts.syngentadigital.co.uk'
    },
    'app.cropwise.com': { host: 'GLOBAL', account: 'accounts.cropwise.com' },
    'uk.app.cropwise.com': { host: 'UK', account: 'uk.accounts.cropwise.com' },
    'app.syngentadigital.co.uk': { host: 'UK', account: 'accounts.syngentadigital.co.uk' }
  };

  const { host } = window.location;
  return {
    host: (!isEmpty(domainMapping[host]) && domainMapping[host].host) || 'GLOBAL',
    account: !isEmpty(domainMapping[host]) && domainMapping[host].account,
    hostsList: [...new Set(Object.values(domainMapping).map((d:any) => d.host))]
  };
};

export const getParentID = () => {
  const urlArr = window.location.href.split("/");
  return urlArr[urlArr.length - 1];
}

export const getPayloadForRBACVerifyAppResource = (apps: any, action: string) => {
  return apps.map((app: any) => {
    return {
      action,
      resource: `crn:app:${app}`
    }
  })
}

export const getLocaleUsingCookies = () => {
  const env = process.env.REACT_APP_envName;
  const key = `i18nextLng_${env}`;
  return cookieStorage.getItem(key);
};

const langs = [
  { key: "en", label: "English (USA)", shortLabel: "EN" },
  { key: "pt-BR", label: "Português", shortLabel: "PT" },
  { key: "fr", label: "French", shortLabel: "FR" },
  { key: "de", label: "German", shortLabel: "DE" },
  { key: "pl", label: "Polski", shortLabel: "PL" },
  { key: "es", label: "Spanish", shortLabel: "ES" },
  { key: "es-419", label: "Spanish (Latin America)", shortLabel: "ES-419" },
  { key: "bg", label: "Bulgarian", shortLabel: "BG" },
  { key: "hu", label: "Hungarian", shortLabel: "HU" },
  { key: "ro", label: "Romanian", shortLabel: "RO" },
  { key: 'el', label: 'Greek', shortLabel: 'EL' },
  { key: 'nl', label: 'Dutch', shortLabel: 'NL' },
  { key: 'da', label: 'Danish', shortLabel: 'DA' },
  { key: 'sv', label: 'Swedish', shortLabel: 'SV' },
  { key: 'uk', label: 'Ukrainian', shortLabel: 'UK' },
  { key: 'it', label: 'Italian', shortLabel: 'IT' }
];

export function isLangCodeValid(key: string) {
  const langFiltered = langs.filter((v) => {
    return v.key === key;
  });
  return langFiltered.length === 1;
}

export const getLocale = () => {
  let lang = getLocaleUsingCookies() || "en";
  if (lang === "pt" || lang === "pt-BR") {
    return "pt-BR";
  } else if (isLangCodeValid(lang.split("-")[0])) {
    return lang.split("-")[0];
  } else {
    return "en";
  }
};


