/**
 * i18n : https://react.i18next.com/latest/using-with-hooks
 */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
import 'moment/locale/pt-br';
import { getLanguageLocale } from "./common-methods";
import { enableDebug } from 'app.constant';

let languageCode = {
  pt: 'pt-BR',
  en: 'en'
};

const lng = getLanguageLocale();
moment.locale(languageCode[lng]);

i18n
.use(Backend)
.use(initReactI18next)
.use(LanguageDetector)
.init({
  lng: lng,
  fallbackLng: 'en',
  react: {
    useSuspense: false
  },
  debug: enableDebug,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: '/i18n/{{lng}}.json'
  }
})
.catch(() => {
  console.log('Fail initing i18n');
});;

export default i18n;