import { IEntityDTO, QuotaData } from "core/utils/BasicModels";

export interface RedeemInfoDTO {
  redeemed_from_id: string;
  redeemed_from_type: RedeemedFromTypeEnum;
}

export interface QuotasEntitiesDTO extends Omit<QuotaData, "appId" | "keyIcon" | "planId"> {
  entitiesToBeAdded: IEntityDTO[]
  entitiesToBeRemoved: string[]
  id: string;
}

export enum RedeemedFromTypeEnum {
  ACCESS_KEY = "ACCESS_KEY",
  VOUCHER = "VOUCHER",
}