import {ACTION_CURRENT_ACCOUNT, ACTION_LOGOUT} from "../actions/login.actions";
import {PayloadAction} from "core/utils/actionUtils";
import {IUserAccount} from "core/accounts/models";
import {clearAccessToken} from "core/common-methods";

export interface AccountsState {
    currentUser?: IUserAccount;
    accounts?: IUserAccount[];
    loading?: boolean;
    error?: any;
}

const initialState: AccountsState = {
};

export const accountsReducer = (state: AccountsState = initialState, action: PayloadAction<any>): AccountsState => {
    switch (action.type) {
        case ACTION_CURRENT_ACCOUNT.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ACTION_CURRENT_ACCOUNT.SUCCESS:
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
            }
        case ACTION_CURRENT_ACCOUNT.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ACTION_LOGOUT.SUCCESS:
            clearAccessToken();
            return {
                ...state,
                currentUser: undefined,
            }
        default:
            return state;
    }
};
