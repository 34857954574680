import { useQuery, useQueryCache, useMutation } from "react-query";
import { request } from "api/api";
import { openNotificationWithIcon } from "../../common/Notification/NotificationToster";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { plansService } from "./index";
import { commonServices } from "./commonServices";
import { iff } from "core/iff";
import { getStoredLanguage, isUK, isUserPrivileged } from "core/common-methods";
import { BatchInviteDTO } from "core/utils/BasicModels";
import { AppConstants } from "core/utils/app.constants";
import React, { Dispatch, SetStateAction } from "react";
import { CreatedWorkspaceNotificationContent } from "pages/account/CreatedWorkspaceNotificationContent";
import { useAuth } from "context/auth";
import { chunkArray } from "./accounts.util";

const accountService = (function () {
  let content = {
    members: [] as any,
    roles: [] as any,
    status: false,
  };

  let currentPage = 1;
  let currentOrgPage = 0;
  const useAllAccounts = (
    searchQuery: any,
    pagination: any,
    setLoadingData?: any,
    userHasRBACPermission: boolean = false
  ) => {
    const queryCache = useQueryCache();
    currentPage = pagination.page;
    const params = commonServices.getListQueryParams(
      [`useAllAccounts`, currentPage],
      searchQuery,
      pagination,
      queryCache
    );
    const fetchUrl = userHasRBACPermission
      ? "/v2/workspaces"
      : "/v2/accounts/workspaces";
    return useQuery(
      [`useAllAccounts`, currentPage],
      () =>
        request.get(fetchUrl, { params: params }).then((res) => {
          if (setLoadingData) {
            setLoadingData(false);
          }
          return res.data;
        }),
      {
        keepPreviousData: true,
        retry: false,
      }
    );
  };

  const useWorkspaces = async (userHasSystemAccess?: boolean) => {
    // size = 10000 safe number for Licensing, Big Int is crashing on production for now.
    const fetchUrl = userHasSystemAccess
      ? "/v2/workspaces?size=10000"
      : "/v2/accounts/workspaces";
    return request
      .get(fetchUrl)
      .then((res) => res.data.content)
      .catch((e) => e);
  };

  const getWorkspaceById = (id: string) => {
    return request
      .get(`/v2/workspaces/${id}`)
      .then((res) => res.data)
      .catch((e) => e);
  };

  const useGetAccountById = (id: string) => {
    return useQuery(
      ["useAccountDetails", id],
      () => request.get(`/v2/workspaces/${id}`).then((res) => res.data),
      {
        enabled: id,
        retry: false,
      }
    );
  };

  const buildOrgRelation = async (
    response: any,
    params: any,
    t: any,
    orgsData?: any
  ) => {
    const { organizations, id: accId } = response.data;
    if (organizations && orgsData) {
      await Promise.all(
        organizations.map(async (id: any) => {
          const currentOrg = orgsData.find((o: any) => o.id === id);
          if (currentOrg) {
            await request.put(`/v2/orgs/${id}`, {
              ...currentOrg,
              licensing_account_id: accId,
            });
          }
        })
      );
    }
    if (params.id) {
      openNotificationWithIcon(
        "success",
        t("Workspace updated successfully"),
        "topRight"
      );
    } else {
      openNotificationWithIcon(
        "success",
        t("Workspace created successfully"),
        "topRight",
        <CreatedWorkspaceNotificationContent t={t} />,
        { width: "424px" },
        0
      );
    }
    return response;
  };

  const useCreateEditWorkspace = () => {
    const { t } = useTranslation();
    const queryCache = useQueryCache();

    // const orgsData = queryCache.getQueryData('useAllOrganizations');
    const orgsData: any[] = [];

    return useMutation(
      (params) =>
        params.id
          ? request.put(`/v2/workspaces/${params.id}`, params)
          : request.post("/v2/workspaces?set_workspace_owner=true", params),
      {
        onSuccess: (response, params) => {
          buildOrgRelation(response, params, t, orgsData);
          queryCache.invalidateQueries([`useAccountDetails`, params.id]);
        },
        onError: (error: any, _params: any, rollback: any) => {
          openNotificationWithIcon(
            "error",
            `${t("sorry, something wrong happened. Response from server is")} : 
            ${get(error, "response.status")}, ${get(
              error,
              "response.data.error"
            )}`,
            "bottomLeft"
          );
          if (rollback) rollback();
        },
        onSettled: () => {
          queryCache.invalidateQueries([`useAllAccounts`, currentPage]);
        },
      }
    );
  };

  const useDeleteAccountById = () => {
    const { t } = useTranslation();
    const queryCache = useQueryCache();
    return useMutation((id) => request.delete(`/v2/workspaces/${id}`), {
      onMutate: (id) => {
        const oldAccounts = queryCache.getQueryData([
          `useAllAccounts`,
          currentPage,
        ]);
        return () =>
          queryCache.setQueryData([`useAllAccounts`, currentPage], oldAccounts);
      },
      onSuccess: () => {
        openNotificationWithIcon(
          "success",
          t("Licencing Account deleted successfully"),
          "bottomRight"
        );
      },
      onError: (error: any, _params: any, rollback: any) => {
        openNotificationWithIcon(
          "error",
          `${t("sorry, something wrong happened. Response from server is")} : 
            ${get(error, "response.status")}, ${get(
            error,
            "response.data.error"
          )}`,
          "bottomLeft"
        );
        if (rollback) rollback();
      },
      onSettled: () => {
        queryCache.invalidateQueries([`useAllAccounts`, currentPage], {
          exact: true,
        });
      },
    });
  };

  const useAllAccountorgs = (
    searchQuery: any,
    pagination: any,
    setLoadingData?: any,
    userHasRBACPermission: boolean = false
  ) => {
    const queryCache = useQueryCache();
    currentOrgPage = pagination.page - 1;
    const params = commonServices.getListQueryParams(
      [`account/orgs/${searchQuery.parentID}`, currentOrgPage],
      searchQuery,
      pagination,
      queryCache
    );
    const { isUserUsingRBAC } = useAuth();
    const canUserViewExplicitOrgs = isUserUsingRBAC
      ? userHasRBACPermission
      : isUserPrivileged();
    params.attributes = "workspaces";
    params.force_list_unexplicit_orgs = canUserViewExplicitOrgs;
    params.workspaces = searchQuery.parentID;
    params.page = currentOrgPage;

    return useQuery(
      [`account/orgs/${searchQuery.parentID}`, currentOrgPage],
      () =>
        request.get(`/v2/orgs`, { params: params }).then((res) => {
          return res.data;
        }),
      {
        enabled: searchQuery.parentID,
        keepPreviousData: true,
        retry: false,
      }
    );
  };

  const getAccountMembersRoles = async () => {
    const membersIds = content.members.map((member: any) => member.id);
    // v2/accounts/ids/roles is limited to only 100 members/request
    // chunked the array to 50 members/request to not overload the api
    const chunkedMembersIds = chunkArray<string>(membersIds, 50);

    const responses = await Promise.all(
      chunkedMembersIds.map((chunk) => getAccountMembersRolesRequest(chunk))
    );
    content.roles = responses.flat();
    return content;
  };

  const getAccountMembersRolesRequest = async (
    ids: string[],
    lastKey?: string
  ) => {
    const rolesResponse = await request
      .post(`v2/accounts/ids/roles${lastKey ? `?last_key=${lastKey}` : ""}`, {
        ids: ids,
      })
      .catch((e) => {
        console.log(e);
        return e;
      });

    if (rolesResponse.data) {
      const roles = rolesResponse.data.content ?? [];
      if (rolesResponse?.data?.pageable?.next_key) {
        roles.push(
          ...(await getAccountMembersRolesRequest(
            ids,
            rolesResponse?.data?.pageable?.next_key
          ))
        );
        return roles;
      }
      return roles;
    }

    return [];
  };

  const useAllAccountmembers = (
    workspaceId: string,
    setHasLoadedMembers?: Dispatch<SetStateAction<boolean>>,
    canUserViewMembers: boolean = true
  ) => {
    return useQuery(
      ["account/users", workspaceId, currentPage, canUserViewMembers],
      () => {
        if (workspaceId && canUserViewMembers) {
          return request
            .get(`v2/workspaces/${workspaceId}/orgs/accounts`)
            .then(async (res) => {
              if (res.data) {
                content.members = res.data.content;
                content.status = iff(res.status === 200, true);
                if (content.status === true) {
                  await getAccountMembersRoles();
                }
                if (setHasLoadedMembers) {
                  setHasLoadedMembers(true);
                }
                return content;
              }
            });
        }
        if (setHasLoadedMembers) {
          setHasLoadedMembers(true);
        }
        return undefined;
      },
      {
        retry: false,
      }
    );
  };

  const getResourceAttachmentIds = (permission: string) => {
    const resourceAttachment = permission.split("#")[0];
    if (resourceAttachment.includes("crn:system")) {
      return null;
    }
    const workspaceId = resourceAttachment?.split(":")[2]?.split("/")[0];
    const orgId = resourceAttachment?.split(":")[2]?.split("/")[2];
    const propertyId = resourceAttachment?.split(":")[2]?.split("/")[4];
    if (propertyId) {
      return {
        workspace_id: workspaceId,
        org_id: orgId,
        property_id: propertyId,
      };
    }
    if (orgId) {
      return {
        workspace_id: workspaceId,
        org_id: orgId,
      };
    }
    return {
      workspace_id: workspaceId,
    };
  };

  const useAllPlans = () => {
    return useQuery(
      `useAllPlansForContracts`,
      () =>
        request.get(`/v2/plans`).then((res) => res.data && res.data.content),
      {
        retry: false,
      }
    );
  };

  const useGetAccountsPlanAndProduct = (planId: string) => {
    const { data: plan } = plansService.useGetPlanById(planId);
    const appId = plan?.app;
    return useQuery(
      [`contract/plan/apps`, planId],
      () =>
        request.get(`/v2/apps/${appId}`).then((res) => {
          res.data.name = `${res.data.name} - ${plan?.name}`;
          return res.data;
        }),
      {
        enabled: appId,
        retry: false,
      }
    );
  };
  const removeMemberAccess = async (accountId: string, payload: object) => {
    let response = {
      status: false,
    };

    const { data } = await request
      .post(`v2/accounts/${accountId}/roles/updates`, payload)
      .catch((e) => {
        console.log(e);
        return e;
      });

    if (data && data.accepted_roles?.length >= 1) {
      response.status = true;
    }
    return response;
  };

  const batchInviteUsers = async (usersInviteDTO: any) => {
    let response = {
      status: false,
    };
    const { data } = await request
      .post(`v2/accounts/batch/invitation`, usersInviteDTO)
      .catch((e) => {
        return e;
      });
    if (data) {
      response.status = true;
    }
    return response;
  };

  const editAccountRoles = async (accountId: string, rolesUpdatesDTO: any) => {
    let response = {
      status: false,
    };
    const { data } = await request
      .post(`v2/accounts/${accountId}/roles/updates`, rolesUpdatesDTO)
      .catch((e) => {
        return e;
      });
    if (data) {
      response.status = true;
    }
    return response;
  };

  // This service should be called only for privileged users (legacy) or users with members:read system (RBAC)
  const getWorkspacesByName = async (name: string) => {
    const fetchUrl = `/v2/workspaces?name=${name}`;
    return request
      .get(fetchUrl)
      .then((res) => res.data.content)
      .catch((e) => e);
  };

  const builBatchInviteUsersPayload = (
    usersToBeInvited: any,
    rolesToBeAdded: any
  ) => {
    const accountsURL = isUK()
      ? process.env.REACT_APP_ACCOUNTS_UK_URL
      : process.env.REACT_APP_ACCOUNTS_CW_URL;
    return {
      type: "USER",
      locale: getStoredLanguage() || "en",
      emails: usersToBeInvited,
      role: "OTHER",
      client_id: AppConstants.O_AUTH.clientId,
      redirect_uri: `${accountsURL}/authenticate`,
      rbac_roles: rolesToBeAdded,
    } as BatchInviteDTO;
  };

  const buildRemoveWorkspaceRoleFromUser = (
    roleId: string,
    workspaceId: string
  ) => {
    return {
      updates: [
        {
          operation: "REMOVE",
          roles: [
            {
              role_context: "system",
              role_id: roleId,
              workspace_id: workspaceId,
            },
          ],
        },
      ],
    };
  };

  return {
    useAllAccounts: useAllAccounts,
    useWorkspaces: useWorkspaces,
    useCreateEditWorkspace: useCreateEditWorkspace,
    buildOrgRelation: buildOrgRelation,
    useGetAccountById: useGetAccountById,
    useDeleteAccountById: useDeleteAccountById,
    useAllAccountorgs: useAllAccountorgs,
    useAllPlans: useAllPlans,
    useAllAccountmembers: useAllAccountmembers,
    useGetAccountsPlanAndProduct: useGetAccountsPlanAndProduct,
    getResourceAttachmentIds: getResourceAttachmentIds,
    removeMemberAccess: removeMemberAccess,
    getAccountMembersRoles: getAccountMembersRoles,
    getAccountMembersRolesRequest: getAccountMembersRolesRequest,
    batchInviteUsers: batchInviteUsers,
    editAccountRoles: editAccountRoles,
    getWorkspacesByName: getWorkspacesByName,
    builBatchInviteUsersPayload: builBatchInviteUsersPayload,
    buildRemoveWorkspaceRoleFromUser: buildRemoveWorkspaceRoleFromUser,
    getWorkspaceById,
  };
})();

export default accountService;
