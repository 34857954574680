import { store } from "app-redux/store";
import React from "react";
import ReactDOM from "react-dom";
import { ReactQueryDevtools } from "react-query-devtools";
import { Provider } from "react-redux";
import App from "./App";
import AppProviders from "./context";
import "./core/i18n";
import { Modal, notification } from "antd";
import { initClients } from "init-cw-elements";
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import * as Sentry from "@sentry/react";
import { SENTRY_CONFIG, SENTRY_KEY } from 'sentry.config';
import { isLocal } from 'app.constant';

if (!isLocal && SENTRY_KEY) {
  Sentry.init(SENTRY_CONFIG);
}

(async function () {
  await initClients();

  Modal.config({
    rootPrefixCls: "syngenta-ant",
  });

  notification.config({
    prefixCls: "syngenta-ant-notification",
  });

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHY_DARKLY_ID ?? '',
  });

  ReactDOM.render(
    <Sentry.ErrorBoundary showDialog>
      <Provider store={store}>
        <LDProvider>
          <AppProviders>
            <App />
            {process.env.REACT_APP_ENV === "local" && (
              <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            )}
          </AppProviders>
        </LDProvider>
      </Provider>
    </Sentry.ErrorBoundary>,
    document.getElementById("root")
  );
})();
