import { setToken } from '@cw-elements/config';
import { AxiosResponse } from 'axios';
import { IUserAccount } from 'core/accounts/models';
import {AuthenticationOptions, defaultAuthRequestParams, IAuthTokenResponseDTO} from 'core/authentication/models';
import { axiosRequest, fetchRequest, headers } from 'core/axios';
import { getHeaders } from 'core/common-methods';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import qs from 'querystring';
import { Dispatch } from 'redux';
import * as Sentry from '@sentry/react';

export const ACTION_USER_LOGIN: RequestAction = createRequestAction('USER_LOGIN_REQUEST');
export const ACTION_CURRENT_ACCOUNT: RequestAction = createRequestAction('CURRENT_ACCOUNT_REQUEST');
export const ACTION_LOGOUT: RequestAction = createRequestAction('USER_LOGOUT_REQUEST');

export const initOAuth2Flow = (returnStateValue?: string) => {
  const returnState = (returnStateValue) ? `&state=${btoa(returnStateValue)}` : '';
  window.location.href = `${process.env.OAUTH_URL}/oauth/authorize?response_type=code&client_id=${AuthenticationOptions.clientId}&redirect_uri=${AuthenticationOptions.redirectUri}${returnState}`;
};

export const performLogin = (code: string) => async (dispatch: Dispatch) => {
  const config = {
    headers,
  };

  dispatch({ type: ACTION_USER_LOGIN.REQUEST });

  const authTokenRequestDTO = {
    ...defaultAuthRequestParams,
    code,
  };

  try {
    const response = await axiosRequest
            .post<any, AxiosResponse<IAuthTokenResponseDTO>>(`/oauth/token`, qs.stringify(authTokenRequestDTO as any),
                config,
            );

    const tokens = response.data;
    dispatch({ type: ACTION_USER_LOGIN.SUCCESS, payload: tokens });

    localStorage.setItem('tokens', JSON.stringify(tokens));

    if(tokens?.access_token) {
      setToken(tokens.access_token)
    }

    // @ts-ignore
    dispatch(fetchCurrentUser());

  } catch (e) {
    dispatch({ type: ACTION_USER_LOGIN.ERROR, payload: e });
    throw e;
  }

};

export const fetchCurrentUser = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_CURRENT_ACCOUNT.REQUEST });
    const currentUser = await axiosRequest
        .get<any, AxiosResponse<IUserAccount>>(`/v2/accounts/me`,
      {
        headers: getHeaders().common,
      },
      );

    dispatch({ type: ACTION_CURRENT_ACCOUNT.SUCCESS, payload: currentUser.data });
    Sentry.setUser({
      user: {
        id: currentUser.data.id,
        email: currentUser.data.email,
        name: currentUser.data.name,
        language: currentUser.data.locale
      }
    });
    return currentUser.data;
  } catch (e) {
    dispatch({ type: ACTION_CURRENT_ACCOUNT.ERROR, payload: e });
    throw e;
  }
};

export const logout = () => async (dispatch: Dispatch) => {

  try {
    dispatch({ type: ACTION_LOGOUT.REQUEST });

    const myRequest = fetchRequest('/logout', {
      method: 'GET',
      mode: 'no-cors',
      credentials: 'include',
    });
    fetch(myRequest).then();

    dispatch({ type: ACTION_LOGOUT.SUCCESS });
  } catch (e) {
    dispatch({ type: ACTION_LOGOUT.ERROR, payload: e });
    throw e;
  }
};
