import {createRequestAction, RequestAction} from "core/utils/actionUtils";
import { Dispatch } from 'redux';

export const ACTION_CREATE_API_CLIENT: RequestAction = createRequestAction('CREATE_API_CLIENT_REQUEST');
export const ACTION_GET_API_CLIENT: RequestAction = createRequestAction('GET_API_CLIENT_REQUEST');

export const fetchApiClients = () => async (dispatch: Dispatch) => {
    try{
        dispatch({ type: ACTION_GET_API_CLIENT.REQUEST })

        const data = [
            {
                key: '0', 
                apiName: 'Backend API Client', 
                createdAt: 'March 06, 2020', 
                clientId: '5Z8GJRG9W98GBMMW',
                apiAuthURI: [
                    'https://protector.cropwise.com/authenticate',
                ],
                redirectURIs: 1,
                lastAccess: 'March 06, 2020', 
            },
            {
                key: '1',
                apiName: 'Frontend API Client', 
                createdAt: 'August 28, 2020', 
                clientId: 'RVDN2YM2HLCA4HKS',
                apiAuthURI: [
                    'https://protector.cropwise.com/authenticate',
                    'http://localhost:1234/authenticate'
                ],
                redirectURIs: 2,
                lastAccess: 'August 28, 2020', 
            },
            {
                key: '2', 
                apiName: 'Mobile API Client', 
                createdAt: 'August 28, 2020', 
                clientId: '9V9STV2XFJM3CU2H',
                apiAuthURI: [
                    'https://protector.cropwise.com/authenticate',
                    'http://localhost:1234/authenticate'
                ],
                redirectURIs: 2,
                lastAccess: 'August 28, 2020', 
            },
            
        ];

        dispatch({ type: ACTION_GET_API_CLIENT.SUCCESS, payload: data })

        return data;
    } catch(e){
        dispatch({ type: ACTION_GET_API_CLIENT.ERROR, payload: e });
        throw e;
    }
}

export const createApiClient = () => async (dispatch: Dispatch) => {
    try{
        dispatch({ type: ACTION_CREATE_API_CLIENT.REQUEST})

        const data = {
            apiClient:{
                key: '0', 
                apiName: 'Protector Backend Client', 
                createdAt: 'March 06, 2020',
                apiAuthURI: [
                    'https://protector.cropwise.com/authenticate',
                    'http://localhost:1234/authenticate'
                ],
                redirectURIs: 2,
                lastAccess: 'August 28, 2020', 
            },
            client:{
                id: '47656E6572617465644279436169756269', 
                secret: 'NCSZTXZ3SQUUJ35QZE632QCT4VAJG8R2'
            }
        }

        dispatch({ type: ACTION_CREATE_API_CLIENT.SUCCESS, payload: data })
        return data;
        
    } catch(e){
        dispatch({ type: ACTION_CREATE_API_CLIENT.ERROR, payload: e});
        throw e;
    }
}