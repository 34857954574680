import {PayloadAction} from "core/utils/actionUtils";
import { ACTION_CREATE_API_CLIENT, ACTION_GET_API_CLIENT } from "app-redux/actions/api-client.actions";

export interface ApiClient {
    key?: string;
    apiName?: string;
    createdAt?: string;
    apiAuthURI?: [];
    redirectURIs?: number;
    lastAccess?: string;
}

export interface ApiClientState {
    apiClients?: ApiClient[];
    loading?: boolean;
    error?: any;
}

const initialState: ApiClientState = {
};

export const apiClientReducer = (state: ApiClientState = initialState, action: PayloadAction<any>): ApiClientState => {
    switch (action.type) {
        case ACTION_GET_API_CLIENT.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ACTION_GET_API_CLIENT.SUCCESS:
            return {
                ...state,
                loading: false,
                apiClients: action.payload,
            }
        case ACTION_GET_API_CLIENT.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            } 
        case ACTION_CREATE_API_CLIENT.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ACTION_CREATE_API_CLIENT.SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case ACTION_CREATE_API_CLIENT.ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            } 
        default:
            return state;
    }
}