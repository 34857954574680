import { request } from 'api/api';
import { useQuery, useQueryCache } from 'react-query';
import { appServices } from 'core/services';
import { commonServices } from './commonServices';

var PlansService = function () {
  let currentPage = 1;
  const useAllPlans = (searchQuery: any, pagination: any, setLoadingData?: any) => {
    const queryCache = useQueryCache();
    currentPage = pagination.page;
    const params = commonServices.getListQueryParams([`useAllPlans/${searchQuery.parentID}`, currentPage], searchQuery, pagination, queryCache);

    return useQuery(
      `useAllPlans/${searchQuery.parentID} - ${currentPage} - ${pagination?.size} - ${searchQuery?.searchInputValue}`,
      () => request.get(`/v2/apps/${searchQuery.parentID}/plans`, { params: params }).then((res) => {
        if (setLoadingData) {
          setLoadingData(false);
        }
        return res.data;
      }),
      {
        enabled: searchQuery.parentID,
        keepPreviousData: true,
        retry: false
      }
    );
  }

  const getPlansByAppId = async (searchQuery: any, pagination: any) => {
    currentPage = pagination.page;
    const res = await request.get(`/v2/apps/${searchQuery.parentID}/plans`);
    return res.data;
  }

  const useGetPlanById = (id: string) => {
    return useQuery(
      [`useAllPlans`, id],
      () => request.get(`/v2/plans/${id}`).then(res => res.data),
      {
        enabled: id,
        retry: false
      }
    );
  }

  const useGetPlanByIdAsync = async (id: string) => {
    return await request.get(`/v2/plans/${id}`).then(res => res);
  }

  const useAllPlansForCampaigns = () => {
    return useQuery(
      `useAllPlansForCampaigns`,
      () => request.get(`/v2/plans`).then(res => res.data && res.data.content),
      {
        retry: false
      }
    );
  }

  const useGetMetaTitle = (searchQuery: any) => {
    return appServices.useGetAppById(searchQuery.parentID);
  }

  return {
    useAllPlans: useAllPlans,
    getPlansByAppId: getPlansByAppId,
    useGetPlanById: useGetPlanById,
    useGetPlanByIdAsync: useGetPlanByIdAsync,
    useAllPlansForCampaigns: useAllPlansForCampaigns,
    useGetMetaTitle: useGetMetaTitle
  };
}();

export default PlansService;