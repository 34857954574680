import React from 'react';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { AuthProvider } from './auth';
import { ThemeProvider } from '../core/theme';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function AppProviders(props: any) {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ThemeProvider>
        <AuthProvider>
          {props.children}
        </AuthProvider>
      </ThemeProvider>
    </ReactQueryCacheProvider>
  )
}

export default AppProviders;
