import { ACTION_CREATE_APP, ACTION_GET_APP, ACTION_GET_APPS_LIST, ACTION_RESET_GET_APP } from 'app-redux/actions/apps.actions';
import { PayloadAction } from 'core/utils/actionUtils';
import { UUID } from 'core/utils/BasicModels';

export interface App {
  key?: string;
  id?: UUID;
  name?: string;
  description?: string;
  status?: string;
}

export interface AppsState {
  currentAppId?: UUID;
  apps?: App[];
  loading?: boolean;
  error?: any;
}

const initialState: AppsState = {
};

export const appsReducer = (state: AppsState = initialState, action: PayloadAction<any>): AppsState => {
  switch (action.type) {
    case ACTION_GET_APPS_LIST.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_GET_APPS_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        apps: action.payload,
      };
    case ACTION_GET_APPS_LIST.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTION_CREATE_APP.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_CREATE_APP.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION_CREATE_APP.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTION_GET_APP.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_GET_APP.SUCCESS:
      return {
        ...state,
        loading: false,
        currentAppId: action.payload,
      };
    case ACTION_GET_APP.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTION_RESET_GET_APP.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_RESET_GET_APP.SUCCESS:
      return {
        ...state,
        loading: false,
        currentAppId: action.payload,
      };
    case ACTION_RESET_GET_APP.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
