import { combineReducers } from 'redux';
import { accountsReducer, AccountsState } from './accounts.reducer';
import { apiClientReducer, ApiClientState } from './api-clients.reducer';
import { appsReducer, AppsState } from './apps.reducer';
import { OrgsState, orgsStateReducer } from './orgs.reducer';
import { rolloutReducer, RolloutState } from './rollout.reducer';

export interface AppState {
  accounts: AccountsState;
  apps: AppsState;
  apiClients: ApiClientState;
  orgsState: OrgsState;
  rollout: RolloutState;
}

export const rootReducer = combineReducers<AppState>({
  accounts: accountsReducer,
  apps: appsReducer,
  apiClients: apiClientReducer,
  orgsState: orgsStateReducer,
  rollout: rolloutReducer,
});
